import React, { useContext, useEffect } from "react";
import GoogleMap from "../../../components/Map";
import { gMapApiKey } from "../../../config/config";
import eventContext from '../../../contexts/EventContext'
import ReactHtmlParser from 'react-html-parser'
import { getTranslation, replaceAll } from "../../../helpers/common";
import { addAttributesToLinks, addBlankToLink } from "../../../helpers/utils";

const EventVenue = ({matchedEventTranslationConfig}) => {
  const { eventData } = useContext(eventContext)

  return (
    <>
      <div className="venue-container " id="venue">
        <div className="row venue-background">
          <div className="venue-header   d-flex justify-content-sm-center justify-content-start align-items-center mt-1 mt-sm-5 pt-sm-3 px-0 ">
            <h1>
            {matchedEventTranslationConfig?.match ?  getTranslation('The', matchedEventTranslationConfig?.translateTo)  : "The"}  <span>{matchedEventTranslationConfig?.match?  getTranslation('Venue', matchedEventTranslationConfig?.translateTo)  : "Venue"} </span> <img className="d-none d-md-inline-block d-lg-inline-block" src="/img/venueicon.svg" alt="" />
            </h1>
          </div>
          <div className="venue-address px-0 px-md-2 pt-0 pt-sm-3 mb-sm-5 pb-2">
            <div className="d-flex justify-content-sm-center align-items-center">
              {eventData.venue && <p>{eventData.venue}</p>}
              {(eventData.city && !eventData.venue&& replaceAll) && <p>{eventData.city}</p>}
            </div>
            {eventData.additional_venue_details_rich_text && <div className="d-flex justify-content-center align-items-center mt-2">
              <p className="venue-sub-address" style={{fontFamily: "Prompt"}}>{ReactHtmlParser(addAttributesToLinks(eventData.additional_venue_details_rich_text.replaceAll('style="font-family: Hind, sans-serif;"', '')))}</p>
            </div>}
            {(eventData.latitude && eventData.longitude) ? (
              <div className='d-flex justify-content-sm-center align-items-center text-start text-sm-center mt-2'>
                <a style={{color:'#007BFF'}} href={`https://www.google.com/maps/search/?api=1&query=${eventData.venue.replace(/ /g, "+")}`} target='_blank'>
                  View on google maps <img src="/img/google-maps.png" alt="View on google maps" style={{ width: "22px", cursor: "pointer" }} />
                </a>
              </div>) : ''
            }
          </div>

        </div>
      </div>
      <style jsx>
        {`  a{
                cusor: pointer !important;
            }
            .venue-container{
                // height: 1000px;
                // background: #FFFFFF url("/img/venue-bg.svg") no-repeat center; 
                background: white;
                // position: relative;   
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .venue-background{
              width: 100%;
              background: #fff url("/img/tendy-Venue-bg.svg") repeat-y;
              background-position: center center;
              word-break: break-word;
          }
            .venue-header h1{
                font-family: Prompt;
                font-style: normal;
                font-weight: 700;
                font-size: 64px;
                line-height: 83px;
                color: #FFFFFF;
                z-index: 4;
                color: #FFFFFF
            }
            .venue-header span{
                font-family: 'Prompt';
                font-style: normal;
                font-weight: normal;
                font-size: 64px;
                line-height: 83px;
                color: #FB5850;
            }
            .venue-header img{
                margin-top: -10px;
                margin-left: -20px;
            }
            .venue-address p{
                max-width: 780px;
                font-family: 'Prompt' !important;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                letter-spacing: 0px;
                line-height: 30px;
                text-align: center;
                color: #FFFFFF;
            }
            .venue-sub-address{
                font-size: 20px !important;
                font-weight: 400 !important;
                line-height: 26px !important;
                color: #FFFFFFB2 !important;
            }
            .map-box-container {
              width:100%;
              background: #FFFFFF url("/img/venue-bg.svg") no-repeat center; 
              background-size: 105%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 4rem;
            }
            .map-box{
              height:400px;
              width:700px;
            }
            .map-container{
              height:100%;
              width:100%;
              position:relative;
              border-radius:20px;
            }
            .no-map{
              background:url('img/map.png');
              position:relative;
            }
            .no-map > *{
              z-index:3;
            }
            .no-map::before{
              content:'';
              height:100%;
              width:100%;
              position:absolute;
              background:#FB5850DD;
            }
            @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
              .no-map::before {
                background-color: #FB5850AA;
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
              }
            }
            @media only screen and (max-width: 768px) {
              .map-box{
                width:90%;
                height:500px;
                left:0;
                right:0;
              }
            }
            @media only screen and (max-width: 576px) {
              .venue-header h1{
                font-weight: 600;
                font-size: 18px;
                line-height: 40px;
                
            }
            .venue-header span{
                font-size: 18px;
                font-weight: 600;
                line-height: 40px;
            }
            .venue-address p{
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
            }
            .venue-sub-address{
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 20px !important;
            }
            .venue-background{
                 margin: 30px 20px;
                 border-radius: 15px;
                 background-color:#260E20;
                 padding:20px;
             }
             .venue-address p{
                text-align: start;
             }

            }
        `}
      </style>
    </>
  );
};

export default EventVenue;