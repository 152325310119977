import React, { useState, useEffect, useContext } from 'react'
import TicketInfo from './TicketInfo'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTicketData } from "../../../../crud/dedicatedEvent-crud";
import { errorToaster } from '../../../../helpers/utils';
import CouponInput from './../../GeneralComponents/CouponInput';
import { pricingSampleData } from '../../../../data/sampleEvent';
import { categorizeTickets, checkTeamBasedRegStatus, getTranslation } from '../../../../helpers/common';
import eventContext from '../../../../contexts/EventContext';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import _ from 'lodash';

const TeamBasedComponent = dynamic(
  () => import("../../GeneralComponents/TeamBased/TeamBasedComponent"),
);

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false
};


const EventPricing = ({ eventUrl, newQuery, samplePage, getTicketDataFunction, hasCoupons, hasAccessCodes, ticketData, setTicketData, isLoading, groupDiscounts , matchedEventTranslationConfig}) => {

  const { eventData } = useContext(eventContext);
  const router = useRouter();

  const [couponCode, setCouponCode] = useState("");
  const [uncategorized, setUncategorized] = useState([]);
  const [categorized, setCategorized] = useState([]);
  const [teamRegStatus, setTeamRegStatus] = useState(0); // 0 -> No team based tickets, 1 -> Only team based tickets, 2 -> team based and individual tickets 
  const [isAccessCode, setIsAccessCode] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);


  const isReferral = !_.isEmpty(newQuery) && newQuery.referred_by !== "" && newQuery.referral_mode !== "" && newQuery.campaign_id !== ""
  useEffect(() => {
    const { uncategorized, categorized } = categorizeTickets(ticketData);
    const teamRegistrationStatus = checkTeamBasedRegStatus(ticketData);
    setExpandedCategories(categorized.map((category) => category.is_expanded));
    setTeamRegStatus(teamRegistrationStatus)
    setUncategorized(uncategorized);
    setCategorized(categorized);
  }, [ticketData])

  const getCouponDetails = (couponDetails = false, coupon, accessCodeTicket = false) => {
    let newTicketDetails = [];
    accessCodeTicket.length > 0 ? newTicketDetails = [...accessCodeTicket] : newTicketDetails = [...ticketData]
    const couponTickets = couponDetails.applicable_tickets;
    newTicketDetails.forEach(ticket => {
      const ticketPrice = ticket.ticket_price
      ticket.final_price = ticketPrice;
      ticket.discount_remaining = null;
      ticket.coupon_applicable = null;
      ticket.discounted_price = null;

      let finalPrice;
      //If coupon code and not access code
      if (couponDetails && !accessCodeTicket) {
        ticket.discount = null;
        if (couponTickets.includes(ticket.ticket_id)) {
          ticket.coupon_applicable = true;
          ticket.discount = couponDetails.discount;
          if (couponDetails.discount_type === 1) {
            finalPrice = ticketPrice - ticket.discount;
            ticket.discounted_price = ticket.discount;
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          } else if (couponDetails.discount_type === 2) {
            finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
            ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          }
          if (finalPrice <= 0) {
            ticket.final_price = 0;
          }
        }
      } else { // If access code
        ticket.coupon_applicable = true;
        if (teamRegStatus > 0) { //If access code is coming from a team based event
          ticket.teamBasedEvent = true;
        }
        if (ticket.discount_type === 1) {
          finalPrice = ticketPrice - ticket.discount;
          ticket.discounted_price = ticket.discount;
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        } else if (ticket.discount_type === 2) {
          finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
          ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        }
        if (finalPrice <= 0) {
          ticket.final_price = 0;
        }
      }
    })
    setCouponCode(coupon);
    setTicketData(newTicketDetails);
  };

  const removeCoupon = (isAccessCode = false) => {
    if (!isAccessCode) {
      let newTicketDetails = [...ticketData]
      newTicketDetails.forEach(ticket => {
        ticket.final_price = ticket.ticket_price;
        ticket.discount = null;
        ticket.discount_remaining = null;
        ticket.discounted_price = null;
      })
      setTicketData(newTicketDetails);
    } else if (isAccessCode) {
      //   setTicketData("");
      getTicketDataFunction();
    }
    setCouponCode("")
  }


  return (
    <>
      {(ticketData?.length > 0 || hasCoupons || hasAccessCodes) && <div className="pricing-container d-flex flex-column align-items-center eventpage-fixed-padding py-0" id="tickets">
        <div className="w-100  py-2 mt-sm-0 mt-sm-3 mb-sm-0 mb-sm-3  mb-0 pb-0 d-flex flex-row justify-content-sm-around justify-content-start align-items-center">
          <h1 className="ticket-txt">{matchedEventTranslationConfig?.match ?  getTranslation('Tickets', matchedEventTranslationConfig?.translateTo)  : `${ticketData?.length === 1 ? 'Ticket' : 'Tickets'}`}  </h1>
          {/* <h1 className="pricing-txt ms-sm-3 ms-2">Pricing</h1> */}
        </div>
        {(hasCoupons || hasAccessCodes && !isLoading) && (
          <>
            <p className='discount-text text-sm-center text-start' style={{ color: "#572148" }}>
              {hasCoupons && !hasAccessCodes && (matchedEventTranslationConfig?.match ? getTranslation('Apply discount code to avail discounts.', matchedEventTranslationConfig?.translateTo) : "Apply discount code to avail discounts.") }
              {!hasCoupons && hasAccessCodes && (matchedEventTranslationConfig?.match ? getTranslation('Apply access code to view hidden tickets.', matchedEventTranslationConfig?.translateTo) : "Apply access code to view hidden tickets.") }
              {hasCoupons && hasAccessCodes &&   (matchedEventTranslationConfig?.match ? getTranslation('Apply discount code / access code to avail discounts & view hidden tickets.', matchedEventTranslationConfig?.translateTo) : "Apply discount code / access code to avail discounts & view hidden tickets") }
            </p>
            <CouponInput
              getCouponDetails={getCouponDetails}
              removeCoupon={removeCoupon}
              newQuery={newQuery}
              setIsAccessCode={setIsAccessCode}
              isAccessCode={isAccessCode}
            />
          </>
        )}
        {teamRegStatus === 0 ? (
          (ticketData.length === 0 ? (
            <div className="ticket-container container mt-4 mt-sm-5 p-3 rounded d-flex justify-content-center">No tickets available </div>
          ) : (
            <div className="ticket-container d-flex flex-column gap-2 container mt-4 mt-sm-5 p-3 px-0 rounded justify-content-center">
              {uncategorized.map((i, index) => <TicketInfo key={index} groupDiscounts={groupDiscounts} eventUrl={eventUrl} data={i} newQuery={newQuery} couponCode={couponCode} />)}
              {
                categorized.length > 0 &&
                <div className='mx-sm-3 mx-0 my-3'>
                  {
                    categorized.map((category, index) => (
                      <div key={category.category_id} className="accordion" id={`accordion-${category.category_id}`}>
                        {
                          category.tickets.length > 0 &&
                          <div className="category-box mt-sm-4 accordion-item">
                            <h2 className="accordion-header" id={`heading-${category.category_id}`} style={{ marginBottom: "0px" }}>
                              <button
                                className={`accordion-button ${expandedCategories[index] ? "" : "collapsed"}`}
                                aria-expanded={expandedCategories[index]}
                                aria-controls={`collapse-${category.category_id}`}
                                onClick={() => {
                                  setExpandedCategories((prev) => {
                                    const newExpandedCategories = [...prev];
                                    newExpandedCategories[index] = !newExpandedCategories[index];
                                    return newExpandedCategories;
                                  });
                                }}
                              >
                                {category.category_name}
                                <span className="accordion-arrow ms-2"></span>
                              </button>
                            </h2>
                            <div
                              id={`collapse-${category.category_id}`}
                              className={`accordion-collapse collapse ${expandedCategories[index] ? "show" : ""}`}
                              aria-labelledby={`heading-${category.category_id}`}
                              data-bs-parent={`#accordion-${category.category_id}`}
                            >
                              <div className='tickets accordion-body  d-flex flex-column gap-4 p-0 p-sm-3'>
                                {category.category_description &&
                                  <div style={{ color: 'rgb(71, 71, 71)', fontSize: '16px' }} className='acc-desc' dangerouslySetInnerHTML={{ __html: category.category_description }} />
                                }
                                {
                                  category.tickets.map(ticket => (
                                    <TicketInfo key={ticket.ticket_id} groupDiscounts={groupDiscounts} eventUrl={eventUrl} data={ticket} couponCode={couponCode} newQuery={newQuery} />
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    ))
                  }
                </div>
              }
            </div>))
        ) : (
          <div className='ticket-container container mt-5 p-3 rounded justify-content-center'>
            <TeamBasedComponent color="#fb5850" teamRegStatus={teamRegStatus} eventData={eventData} onJoinTeam={(team) => {
              sessionStorage.setItem("team_name", team.team_name)
              sessionStorage.setItem("team_id", team.team_id)
              sessionStorage.setItem("team_attendee_count", team.team_attendee_count);
              if (isAccessCode) {
                sessionStorage.setItem("isEventPageTeamComp", true);
              }
              router.push(
                `/checkout/${eventData.event_url}?teamRegType=2&team_name=${team.team_name}&team_id=${team.team_id}${couponCode ? `&selectedCode=${couponCode}` : ""}${isReferral ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}` : ""}${isAccessCode ? `&isAccessCode=1` : ""}`
              )
            }}
              onCreateTeam={(val) => {
                sessionStorage.setItem("team_name", val.team_name)
                sessionStorage.setItem("team_lead_name", val.team_lead_name)
                sessionStorage.setItem("team_lead_email", val.team_lead_email)
                if (isAccessCode) {
                  sessionStorage.setItem("isEventPageTeamComp", true);
                }
                router.push(
                  `/checkout/${eventData.event_url}?teamRegType=1&team_name=${val.team_name}&team_lead_name=${val.team_lead_name}&team_lead_email=${val.team_lead_email}${couponCode ? `&selectedCode=${couponCode}` : ""}${isReferral ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}` : ""}${isAccessCode ? `&isAccessCode=1` : ""}`,
                )
              }}
              onIndividual={() => {
                if (couponCode && isAccessCode) {
                  // if multiple hidden tickets with team_based and without team_based ticket
                  // and if select individual
                  sessionStorage.setItem("isEventPageTeamComp", true);
                  router.push(
                    `/checkout/${eventData.event_url}?teamRegType=0&selectedCode=${couponCode}${isAccessCode ? `&isAccessCode=1` : ""}`
                  )
                } else {
                  router.push(
                    `/checkout/${eventData.event_url}?teamRegType=0`
                  )
                }
              }}
            />
          </div>
        )}
      </div>}
      <style jsx>{`
                .pricing-container{
                    height: auto;
                    overflow: hidden;
                }
                .ticket-txt{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 64px;
                    line-height: 130%;
                    text-align: center;
                    color: #572148;
                }
                .pricing-txt{
                    font-family: Caveat Brush;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 64px;
                    line-height: 130%;
                    color: #FB5850;
                }
                .ticket-container {
                  max-width: 720px;
                  background: #F6F4EF;
                }
                .category-box {
                  border-radius: 5px !important;
                  border: 2px solid #EFE9ED;
                  background: #FFF;
                  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                }
                .accordion-button {
                  font-size: 18px;
                  border-radius: 5px !important;
                  font-weight: 600;
                }
    
                .accordion-button:not(.collapsed) {
                  background-color: #FFF;
                  color: #212529;
                  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
                }
                .acc-desc p  span{
                  color: rgb(71, 71, 71) important;
                  font-size: 16px;
                }
                
                 .accordion-button::after{
                    background-image: url("/img/accordian.svg");
                }
                .accordion-button:not(.collapsed)::after {
                   background-image: url("/img/accordian.svg");                 
                   transform: rotate(-180deg);
                }

                @media only screen and (max-width: 575px) {
                    .ticket-txt{
                      font-size: 18px;
                      font-weight: 600;
                    }
                    .ticket-container {
                      max-width: 720px;
                      background-color: #fff !important;
                    }
                    .eventpage-fixed-padding{
                      padding: 4rem 1.5rem 1rem 1.5rem;
                    }
                    .discount-text{
                      font-size: 16px;
                      margin: 11px 0px;
                    }
                    button {
                      font-size:14px !important;
                    }
                    .accordion-button{
                      font-weight: 500;
                      font-size: 16px !important;
                      padding:16px 16px;
                    }
                    .accordion-body{
                      padding:16px 16px !important;
                    }
                    .acc-desc{
                     font-size:14px;
                     font-weight:300 !important;
                     padding-top:5px;
                   }
                  .accordion-button:not(.collapsed) {
                    box-shadow: 0 0 0 !important;
                     }
                }

                `}</style>
    </>
  )
}

export default EventPricing