import React, { useContext } from "react";
import eventContext from "../../../contexts/EventContext";
import ReadMore from '../../../components/ReadMoreText'
import { getTranslation } from "../../../helpers/common";


const EventAbout = ({eventUrl, hideHighlight , matchedEventTranslationConfig}) => {
    
    const { eventData } = useContext(eventContext);


    return (
        <>
            <div className="about-container pt-sm-5 pt-3 eventpage-fixed-padding" id="about">
                <img className="ticket-img" src="/img/event-about-ticket.svg" />
                <div className=" no-gutters me-0 ms-0">
                    <div className="row about-header pt-2 pt-sm-0 p-0 d-flex justify-content-start align-items-center mb-0 mb-sm-2">
                        <h1 className='p-0'> {matchedEventTranslationConfig?.match ?  getTranslation('About', matchedEventTranslationConfig?.translateTo)  : "About"} </h1>
                    </div>
                    <div className="row ml-0 ml-sm-1 py-2 px-1">
                        <div className="col-12 col-lg-6 col-xl-7 d-flex flex-column " >
                            {eventData.description && <div className="desc my-sm-3 mb-sm-5 mb-1 mt-0  pb-sm-4 pt-0 pt-sm-0" style={{color:"#141B25"}}>
                                <ReadMore
                                    content={eventData.description}
                                    maxChar={"auto"}                
                                    styles={{
                                        fontFamily: "Prompt",
                                        margin: "0px",
                                        fontSize:'16px',
                                    }}
                                />
                            </div>}
                        </div>
                        <div style={{marginBottom: "5.6rem"}} className="col-12 pe-0 px-5 mt-4 about-organiser col-xl-5 justify-content-center col-lg-6 d-flex position-relative pt-lg-5 ps-0 ">
                            <div className="about-patch  px-2 py-3 py-sm-2 px-sm-0 px-0 me-sm-0 pe-sm-5  mb-5 d-flex flex-column">
                                <div className="about-org pt-sm-5 pt-2 w-100 ps-sm-5  p-sm-3 pl-4 pr-2 d-flex flex-column align-items-start justify-content-around">
                                    <h5 className="mt-1">{matchedEventTranslationConfig?.match ?  getTranslation('About Organizer', matchedEventTranslationConfig?.translateTo) : "About Organizer "} </h5>
                                    <div className="d-flex w-100">
                                        {
                                            eventData.organiser_info &&
                                            <span className="organizer-info" >
                                                <ReadMore
                                                    content={eventData.organiser_info}
                                                    maxChar={"auto"}
                                                    styles={{
                                                        fontSize:"16px",
                                                        lineHeight:"22px"
                                                     }}
                                                />
                                            </span>
                                        }

                                    </div>
                                </div>
                                <div className="position-relative d-flex flex-column-reverse d-sm-block details-wrapper">
                                    <div className="about-logo p-3 d-flex flex-column justify-content-center align-items-center">
                                        {eventData.organiser_image_url !== "" ? (
                                            <img
                                                className="logo-short d-flex justify-content-center align-items-center"
                                                src={eventData.organiser_image_url}
                                            />
                                        ) : (
                                            <img
                                                className="logo-short d-flex justify-content-center align-items-center"
                                                src="/img/logo-short.svg"
                                            />
                                        )}
                                    </div>
                                    <div className='ml-auto pr-1 pb-sm-1 pb-4'>
                                        <div className="d-flex about-details flex-column justify-content-center mt-2 mb-2">
                                    
                                          
                                            {eventData.organizer_facebook_url && (
                                                <a
                                                    href={
                                                        eventData.organizer_facebook_url !==
                                                            null
                                                            ? eventData.organizer_facebook_url
                                                            : `${`https://fb.com/${eventData.organiser_name}`}`
                                                    }
                                                    target="_blank"
                                                    className=" share-item d-flex"
                                                >
                                                    <img
                                                        className="shout-share"
                                                        src="/img/u_facebook.svg"
                                                    />
                                                </a>
                                            )}
                                            {eventData.organizer_twitter_url && (
                                                <a
                                                    href={
                                                        eventData.organizer_twitter_url !==
                                                            null
                                                            ? eventData.organizer_twitter_url
                                                            : `${`https://twitter.com/${eventData.organiser_name}`}`
                                                    }
                                                    target="_blank"
                                                    className=" share-item d-flex"
                                                >
                                                    <img
                                                        className="shout-share"
                                                        src="/home/twitter-footer.svg" 
                                                    />
                                                </a>
                                            )}
                                            {eventData?.organizer_linkedin_url && (
                                                <a
                                                    href={
                                                        eventData?.organizer_linkedin_url !==
                                                            null
                                                            ? eventData?.organizer_linkedin_url
                                                            : `${`https://linkedin.com/${eventData?.organiser_name}`}`
                                                    }
                                                    target="_blank"
                                                    className=" share-item d-flex"
                                                >
                                                    <img
                                                        className="shout-share"
                                                        src="/img/u_linkedin.svg"
                                                    />
                                                </a>
                                            )}
                                            {eventData?.organiser_website && (
                                                <div className=" about-item">
                                                    
                                                    <a
                                                        href={
                                                            eventData?.organiser_website
                                                        }
                                                        target="_blank"
                                                        className=""
                                                    >
                                                        <i className="fas fa-globe about-org-icon"></i>
                                                    </a>
                                                </div>
                                            )}
                                            {eventData?.organiser_phone && (
                                                <div className=" about-item">
                                                   
                                                    <a
                                                        href={`tel:${eventData?.organiser_dial_code + eventData?.organiser_phone}`}
                                                        className=" "
                                                    >
                                                    <i className="fas fa-phone-alt about-org-icon"></i>                                                        
                                                    </a>
                                                </div>
                                            )}
                                            <div className=" about-item">
                                                
                                                <a
                                                    href={`mailto:${eventData?.organiser_email}`}
                                                    className=""
                                                >
                                                   <img
                                                    className=""
                                                    src="/img/u-mail.svg"
                                                />
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="about-patch-2" src="/img/about-patch-2.svg" />
            </div>
            <style jsx>
                {`
                .about-container{
                    height: auto;
                    background-color: #F6F4EF;  
                    position: relative;    
                    margin-top: ${!hideHighlight ? "0px" : "31px"};  
                    // padding-top: 5rem;        
                    // padding-bottom: 5rem;        
                }
                .ticket-img{
                    height: 450px;
                    position: absolute;
                    right: -150px;
                    top: -260px;
                    clip-path: polygon(0 51%, 100% 51%, 100% 100%, 0% 100%);
                    height: 449px;
                    right: -2px;
                    width: 347px;
                    top: -261px;
                    transform: scaleX(-1);
                    margin-right: 4px;
                }
                .about-header h1{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 64px;
                    line-height: 100%;
                    color: #572148;
                    z-index: 4;
                }
                .about-link-btn{
                    display:flex;
                    background-color : #F63C3D;
                    width: 250px;
                    z-index: 2;
                    cursor:pointer;
                    box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
                } 
                .about-link-btn:hover > *{
                    color: black !important;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }   
                .about-link-btn:active{
                    box-shadow: none;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .about-link-text{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 0.04em;
                }
                .desc{
                    letter-spacing: 0.04em;
                    font-weight: 400; 
                    font-size: 18px;
                    z-index: 4;
                    word-wrap: break-word;
                }
                .about-patch{
                    position: sticky;
                    background: #F6F4EF url("/img/about-patch.svg") no-repeat;
                    background-size:cover;
                    width: 400px;
                    top: 20px;
                    height: fit-content;
                    border-radius:15px;
                }
                .about-patch-2{
                    position: absolute;
                    bottom: 0px;
                    left: -160px;
                }
                .about-logo{
                    height: 160px;
                    width: 160px;
                    border-radius: 20px;
                    background-color: #fff;
                    box-shadow: -7px -7px 22px 0px rgba(207, 207, 207, 0.6),
                    7px 7px 22px 0px rgba(233, 232, 232, 0.4);
                    position:absolute;
                    left: -40px;
                    z-index:2;
                }
                .about-org h5{
                    font-family: Prompt;
                    font-size: 28px;
                    line-height: 30px;
                    letter-spacing: 0.04em;
                    color: #FB5850;
                }
                .about-org a{
                    color: #572148;
                    font-family: Prompt;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 18px;
                }
                .organizer-info{
                    font-size: 12px;
                    overflow: hidden;
                    color:#141B25;
                }
                .about-details{
                    margin-left: 40%;
                    display: flex !important;
                    flex-direction: row !important;
                    gap: 10px;
                    align-items: center;
                    justify-content: start !important;
                }
                a{
                    cursor:pointer;
                }
                .about-details a{
                    color: #572148;
                    font-family: Prompt;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 18px;
                }
                .logo-short{
                    width: 100%;
                    height: 100%;
                }
                .socials-div{
                    margin-left: 40%;
                }
                .shout-share:hover{
                    filter: none !important;
                }
                .shout-share{
                    transform: scale(1.15);
                    margin-left: 0%;
                    height: 24px;
                    width: 24px;
                }
                .share-item:hover{
                    transform: scale(1.05);
                }
                .about-org-icon{
                    font-size: 20px;
                    color:#572148;
                    vertical-align: middle;
                }
                .about-item {
                    white-space: nowrap;
                    height: 24px;
                    width: 24px;
                }
                .about-item:hover{
                    transform: scale(1.05);
                }
                .rm-typography{
                    margin: 0 !important;
                    padding: 0 !important;
                  }
                  .rm-text-container{
                    margin: 0 !important;
                    padding: 0 !important;
                  }
                  .rm-action-button-container *{
                    border: none !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    background:transparent;
                    text-decoration:underline;
                    font-size:medium;
                    margin-bottom:10px !important;
                    color: #fb5850;
                  }
                  .rm-action-button-container *:focus {
                    box-shadow: none !important;
                  }
                @media only screen and (max-width: 1380px){
                    .about-logo{
                        left: -20px;
                        height: 140px;
                        width: 140px;
                    }
                }
                @media only screen and (max-width: 992px){
                    .desc{
                        font-size: 16px;
                    }
                }
                @media only screen and (max-width: 575px) {

                }
                @media only screen and (max-width: 768px) {
                    .about-link-btn{
                        display:none;
                    }
                    .about-patch-2{
                        top: 0;
                        left: -80px;
                    }
                    .about-header h1{
                        font-size: 42px;
                        padding-left: 14px !important;
                    }
                    .ticket-img{
                        display:none;
                    }
                    .about-logo{
                        height: 140px;
                        width: 140px;
                    }       
                    .shout-share{
                        margin-left: 0%;
                    }        
                }
                @media only screen and (max-width: 576px) {
                    .about-patch {
                        width: 100%;
                        background:#FFDBA6 !important;

                    }
                    .about-patch-2 {
                        transform: rotate3d(7, 125, 188, 17deg);
                        top: -2px;
                        left: -69px;
                    }
                    .about-logo {
                        left: 25%;
                        bottom: -100px;
                        left: 50%;
                        bottom: -100px;
                        right: 50%;
                        transform: translateX(-50%);
                    }
                    .about-details {
                        margin-left: 0px;
                    }
                    .socials-div {
                        margin-left: 5px;
                    }
                    .details-wrapper{
                        padding-bottom: 2.2rem;
                        width: 100%;
                        padding-left: 20px;
                    }
                    .about-org {
                        margin-bottom: .4rem;
                        padding-left: 20px !important;
                        padding-right: 5px !important;
                    }
                    .about-header h1{
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .about-org h5{
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .eventpage-fixed-padding{
                         padding: 4rem 1.5rem 1rem 1.5rem;
                    }
                    .logo-short{
                        border-radius: 14px !important;
                    }
                    .about-logo{
                        padding: 0px !important;
                        border-radius: 14px !important;
                        height: 125px;
                        width: 125px;
                    }
                    .no-gutters {
                        --bs-gutter-x: 0 !important;
                    }
                    .about-patch {
                        width: 100%;
                        max-width: 345px;
                    }
                    .about-patch- {
                        transform: rotate3d(4, 129, 188, 18deg);
                        top: -39.9px;
                        left: -31px;
                        width: 160px;
                        height: 195px;
                        transform: rotate3d(4, 129, 188, 18deg);
                        top: -39.3px;
                        left: -38px;
                        width: 160px;
                        height: 195px;
                    }
                    .about-organiser{
                        margin-bottom: 3rem !important;

                    }

                }
                 @media only screen and (max-width: 376px) {
                    .about-patch {
                        width: 100%;
                        max-width: 315px;
                        margin-left:5px;
                        margin-right:5px;
                     }
                 
                 }

                `}
            </style>
        </>
    );
};

export default EventAbout;